import { resolveComponent as _resolveComponent, createVNode as _createVNode, vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "login-pwd"
};
const _hoisted_2 = {
  class: "login-pwd__box"
};
const _hoisted_3 = {
  class: "login-pwd__input"
};
const _hoisted_4 = ["placeholder"];
const _hoisted_5 = {
  class: "login-pwd__input"
};
const _hoisted_6 = ["placeholder"];
const _hoisted_7 = {
  class: "login-pwd__input"
};
const _hoisted_8 = ["placeholder"];
import { router } from '@/router';
import { UpdatePwd } from '@/api';
import { showLoadingToast, showToast } from 'vant';
import { ref } from 'vue';
export default {
  __name: 'LoginPwd',
  setup(__props) {
    const onClickLeft = () => {
      router.back();
    };
    const oldPwd = ref('');
    const password = ref('');
    const password_confirmation = ref('');
    const _UpdatePwd = () => {
      showLoadingToast();
      UpdatePwd({
        type: 1,
        old: oldPwd.value,
        password: password.value,
        password_confirmation: password_confirmation.value
      }).then(({
        code,
        msg
      }) => {
        showToast(msg);
        if (code === 200) {
          router.back();
        }
      });
    };
    return (_ctx, _cache) => {
      const _component_van_nav_bar = _resolveComponent("van-nav-bar");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_van_nav_bar, {
        fixed: "",
        placeholder: "",
        title: _ctx.$t('Change log in password'),
        "left-arrow": "",
        onClickLeft: onClickLeft
      }, null, 8, ["title"]), _createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_withDirectives(_createElementVNode("input", {
        type: "password",
        placeholder: _ctx.$t('Enter the old password'),
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => oldPwd.value = $event)
      }, null, 8, _hoisted_4), [[_vModelText, oldPwd.value]])]), _createElementVNode("div", _hoisted_5, [_withDirectives(_createElementVNode("input", {
        type: "password",
        placeholder: _ctx.$t('Enter new password'),
        "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => password.value = $event)
      }, null, 8, _hoisted_6), [[_vModelText, password.value]])]), _createElementVNode("div", _hoisted_7, [_withDirectives(_createElementVNode("input", {
        type: "password",
        placeholder: _ctx.$t('Confirm new password'),
        "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => password_confirmation.value = $event)
      }, null, 8, _hoisted_8), [[_vModelText, password_confirmation.value]])]), _createElementVNode("div", {
        class: "login-pwd__btn",
        onClick: _cache[3] || (_cache[3] = $event => _UpdatePwd())
      }, _toDisplayString(_ctx.$t('Submit')), 1)])]);
    };
  }
};